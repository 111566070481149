<template>
  <layout>
    <div class="row mb-3 align-items-center">
      <div class="col">
        <PageHeader :title="title ? 'Add User' : 'Update User'" />
      </div>
      <div class="col-auto">
        <router-link to="/users" class="btn btn-theme">
          <i class="mdi mdi-arrow-left font-size-14 align-middle me-2"></i>
          Back to User List
        </router-link>
      </div>
    </div>
    <b-alert v-model="isResetErrors" variant="danger" class="mt-3" dismissible>
      <span style="margin-left:42%;">{{ errors[0] }}</span>
    </b-alert>
    <b-alert v-if="success" variant="success" class="mt-3" dismissible>{{
      success
    }}</b-alert>
    <div class="card">
      <div class="card-body">
        <form class="needs-validation mb-3" v-if="showForm">
          <div class="row">
            <!-- <div class="col-12">
              <div class="mb-3">
                <label for="date-input" class="form-label">Date</label>
                <div class="input-group w-100 inputFieldDate">
                  <datepicker id="date-input" v-model="form.date" name="dateSelect" @input="dateSelected" format="MMMM dd yyyy"
                    input-class="customClass" placeholder="MM-DD-YYYY"></datepicker>
                  <span class="input-group-text bg-theme"><i class="mdi mdi-calendar text-white"></i></span>
                </div>
              </div>
            </div> -->
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label for="first-name-input">First Name</label>
                <input id="first-name-input" v-model="form.firstName" type="text" class="form-control"
                  placeholder="First Name" :class="{
                    'is-invalid': submitted && v$.form.firstName.$error,
                  }" />
                <div v-if="submitted && v$.form.firstName.$error" class="invalid-feedback">
                  <span v-if="v$.form.firstName.required.$message">
                    {{ v$.form.firstName.required.$message }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label for="last-name-input">Last Name</label>
                <input id="last-name-input" v-model="form.lastName" type="text" class="form-control"
                  placeholder="Last Name" :class="{
                    'is-invalid': submitted && v$.form.lastName.$error,
                  }" />
                <div v-if="submitted && v$.form.lastName.$error" class="invalid-feedback">
                  <span v-if="v$.form.lastName.required.$message">
                    {{ v$.form.lastName.required.$message }}
                  </span>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label for="pen-name-input">Pen Name</label>
                <input id="pen-name-input" v-model="form.penName" type="text" class="form-control"
                  placeholder="Pen Name" />
              </div>
            </div> -->
            <!-- <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label for="street-address-input">Street</label>
                <input id="street-address-input" type="text" v-model="form.street" class="form-control"
                  placeholder="Street" />
              </div>
            </div> -->
            <!-- <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label for="city-address-input">City</label>
                <input id="city-address-input" v-model="form.city" type="text" class="form-control"
                  placeholder="City" />
              </div>
            </div> -->
            <!-- <div class="col-md-6 col-lg-2">
              <div class="mb-3">
                <label for="state-address-input">State</label>
                <input id="state-address-input" v-model="form.state" type="text" class="form-control"
                  placeholder="State" />
              </div>
            </div> -->
            <!-- <div class="col-md-6 col-lg-2">
              <div class="mb-3">
                <label for="zip-address-input">Zip</label>
                <input id="zip-address-input" v-model="form.zipCode" type="text" class="form-control" placeholder="Zip"
                  :class="{
                    'is-invalid': submitted && v$.form.zipCode.$error,
                  }" />
                <div v-if="submitted && v$.form.zipCode.$error" class="invalid-feedback">
                  <span v-if="v$.form.zipCode.required.$message">
                    {{ v$.form.zipCode.required.$message }}
                  </span>
                </div>
              </div>
            </div> -->
            <!-- <div class="col-md-6 col-lg-4">
              <label for="phone-input">Phone</label>
              <div>
                <input v-model="form.phone" id="phone-input" type="text" name="number" class="form-control"
                  @input="formatPhoneNumber" placeholder="xxx-xxx-xxxx" />
              </div>
            </div> -->
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label for="email-input">Email</label>
                <input v-model="form.email" id="email-input" type="email" name="email" class="form-control"
                  placeholder="Enter a Valid Email" @input="isResetErrors = false" />
              </div>
            </div>
            <!-- <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label for="sharedEmail-input">Shared Email
                  <sup>(created by Submit<span class="text-theme">it</span>)</sup></label>
                <input v-model="form.sharedEmail" id="sharedEmail-input" type="email" name="sharedEmail"
                  class="form-control" placeholder="Enter a Shared Email" />
              </div>
            </div> -->
            <div class="col-md-6 col-lg-4" v-if="!$route.params.id">
              <div class="mb-3">
                <label for="shared-email-password-input">Shared Email Password</label>
                <div class="input-group">
                  <input id="shared-email-password-input" v-model="form.sharedEmailPassword" type="text" name="password"
                    class="form-control" placeholder="Password" />
                  <span class="input-group-btn">
                    <button type="button" class="btn btn-theme py-1" @click="generate('sharedEmailPassword')">
                      <span class="mdi mdi-refresh font-size-18"></span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
            <!-- <div class="col-12 mb-3">
              <label class="form-label">Notes</label>
              <textarea type="textarea" v-model="form.notes" placeholder="Type Notes..."
                class="form-control resize-none w-100" rows="6"></textarea>
            </div> -->
          </div>
          <div class="row gx-2">
            <div class="col-auto">
              <button class="btn btn-theme mt-3" type="submit" @click.prevent="formSubmit()">
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/admin/header/page-header";
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import CryptoJS from "crypto-js";
import {
  required,
  helpers
} from "@vuelidate/validators";
export default {
  page: {
    title: "Add User",
    meta: [
      {
        name: "description",
        content: "description",
      },
    ],
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    size: {
      type: String,
      default: "20",
    },
    characters: {
      type: String,
      default: "a-z,A-Z,0-9,#",
    },
    auto: [String, Boolean],
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      url: process.env.VUE_APP_URL,
      form: {
        firstName: "",
        lastName: "",
        penName: "",
        city: "",
        street: "",
        date: new Date(),
        state: "",
        zipCode: "",
        email: "",
        phone: "",
        sharedEmail: "",
      },
      isShowData: false,
      title: true,
      submitted: false,
      editing: true,
      showForm: true,
      isResetErrors: false,
      journals: []
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  validations: {
    form: {
      firstName: {
        required: helpers.withMessage("First Name is required", required),
      },
      lastName: {
        required: helpers.withMessage("Last Name is required", required),
      },
    },
  },
  mounted() {
    if (this.auto == "true" || this.auto == 1) {
      this.generate();
    }
  },
  activated() {
    console.log("activated");
    this.getUserData();
  },
  watch: {
    $route() {
      this.resetFields();
    },
  },
  methods: {
    getUserData() {
      const id = this.decode(this.$route.params.id);
      if (id) {
        this.title = false;
        axios.get(`${this.url}api/admins/${id}`).then((res) => {
          this.form = res.data;
          this.form.date = new Date(this.form.date);
        });
      }
    },
    formSubmit() {
      this.submitted = true;
      this.v$.$touch();
      if (! this.v$.$invalid) {
        const id = this.decode(this.$route.params.id);
        axios[id ? 'put' : 'post'](`${this.url}api/admins` + (id ? `/${id}` : ''), this.form)
          .then((res) => {
            if (res.data.error) {
              this.isResetErrors = true;
              this.errors = res.data.message;
            } else {
              this.isResetErrors = false;
              this.$store.state.isAccepted = true
              this.success = res.data.message;
              this.$store.state.notificationData = `User has been successfully ${id ? 'updated' : 'added'}`;
        
              this.$router.push({ path: "/users" });
            
              setTimeout(() => {
                this.$store.state.isAccepted = false
                this.$store.state.notificationData = null
              }, 2500)
            }
          })
          .catch((error) => {
            this.isResetErrors = true;
            this.errors = error.response.data.errors.email;
          });
      }
    },
    dateSelected(date) {
      var getYear = date.toLocaleString("default", { year: "numeric" });
      var getMonth = date.toLocaleString("default", { month: "2-digit" });
      var getDay = date.toLocaleString("default", { day: "2-digit" });
      this.form.date = getYear + "-" + getMonth + "-" + getDay;
    },
    generate(type = 'password') {
      let CharacterSet =
        "abcdefghijklmnopqrstuvwxyz![]{}()ABCDFASDFFG1234582475%&*$#^<>~@|";
      let password = "";
      for (let i = 0; i < this.size; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      this.form[type] = password;
    },
    formatPhoneNumber() {
      this.form.phone = this.form.phone.replace(/\D/g, "");
      if (this.form.phone.length > 3) {
        this.form.phone = this.form.phone.replace(
          /(\d{3})(\d{0,3})(\d{0,4})/,
          "$1-$2-$3"
        );
      } else {
        this.form.phone = this.form.phone.replace(
          /(\d{0,3})(\d{0,3})/,
          "$1-$2"
        );
      }
      this.form.phone = this.form.phone.slice(0, 12);
    },
    resetFields() {
      this.submitted = false;
      this.isResetErrors = false;
      this.form = {
        firstName: "",
        lastName: "",
        penName: "",
        city: "",
        street: "",
        date: new Date(),
        state: "",
        zipCode: "",
        email: "",
        phone: "",
        referral: "",
        sharedEmail: "",
      };
      this.title = true;
    },
    decode(id) {
      return decodeURIComponent(CryptoJS.AES.decrypt(String(id), "Secret Passphrase").toString(CryptoJS.enc.Utf8));
    },
  },
};
</script>

<style lang="css">
</style>